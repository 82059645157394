import React from "react"
import styled from "@emotion/styled"
import { graphql, useStaticQuery } from "gatsby"
import { format, replacePunctuation } from "../services/utils"
import { keyframes } from "@emotion/core"

const marquee = keyframes`
  0% {
    transform: translate(0%) scale(1.6, 1.2);
  }
  100% {
    transform: translate(-100%) scale(1.6, 1.2);
  }
`
const BannerBack = styled.div`
  display: none;
  @media screen and (max-width: 460px) {
    content: '';
    position: fixed;
    display: inline-block;
    width: 80vw;
    bottom: 0;
    right: -6em;
    backdrop-filter: blur(6px);
    transform: rotate(-45deg);
    z-index: 10;
    height: 2.3em;
  }
`

const Text = styled(props => <a {...props} />)`
  display: none;
  @media screen and (max-width: 460px) {
    display: inline-block;
    transform: scale(1.6, 1.2);
    z-index: 10;
    white-space:nowrap;
    padding-left: 80vw;
    padding-top: .5em;
    animation: ${marquee} 50s linear infinite;
  }
`
const Banner = () => {
  const data = useStaticQuery(graphql`
    query BannerQuery {
      feedTestNote {
        title
        contentSnippet
        isoDate
        link
      }
    }
  `)
  const snippet = replacePunctuation(data.feedTestNote.contentSnippet)
  const date = data.feedTestNote.isoDate.replace('-', '/').replace('-', '/').substring(0, 10) // TODO serviceの関数使いたい
  return (
    <>
      <BannerBack>
        <Text href={data.feedTestNote.link} target="_blank">note最新記事{"▼"}{date}{"▼"}{data.feedTestNote.title}{"▼"}{snippet}</Text>
      </BannerBack>
    </>
  )
}

export default Banner
