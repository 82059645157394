import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import { graphql, Link, useStaticQuery } from 'gatsby';
import { isLoggedIn } from '../services/auth';

const Header = styled(BackgroundImage)`
  width: 100vw;
  height: 5em;
  position: relative;
`
const Nav = styled.nav`
  width: 100vw;
  height: 6em;
  position: absolute;
  display: block;
  top: 0;
  backdrop-filter: blur(6px);
`

const Logo = styled.p`
  line-height: 1em;
  display: inline-block;
  position: absolute;
  top: 2em;
  transform: scale(1.2, 1.9);
  font-size: 1em;
  text-indent: 6em;
  @media screen and (max-width: 460px) {
    text-indent: 2em;
  }
`

const Index = styled.p`
  line-height: 1em;
  display: inline-block;
  position: fixed;
  top: 1.5em;
  right: 6em;
  transform: scale(1.2, 1.9);
  font-size: 1em;
  z-index: 999;
  backdrop-filter: blur(6px);
  padding: 0.5em;
  @media screen and (max-width: 460px) {
    right: 2em;
  }
`

const Menu = () => {
  const data = useStaticQuery(graphql`
    query MenuQuery {
      allContentfulAsset(filter: {title: {glob: "main_*"}}) {
        edges {
          node {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `)
  const randomNumber = Math.floor(Math.random() * 5)
  const image = data.allContentfulAsset.edges[randomNumber]
  return (
    <>
      <Header
        Tag="header"
        fluid={image.node.fluid}
        backgroundColor={'#6d846f'}
      >
        <Nav>
          <Logo>クバへ／クバから</Logo>
        </Nav>
      </Header>
      {isLoggedIn() ? (
        <Index><Link to="/app/index">目次</Link></Index>
      ) : null}
    </>
  )
}

export default Menu
