import React, { useEffect } from "react"
import styled from '@emotion/styled'
import { Global, keyframes } from '@emotion/core'
import Menu from './Menu'
import Footer from './Footer'
import { globalStyles } from '../styles/globalStyles.js'
import Banner from "./banner"

const Root = styled.div`
  font-family: ${props => props.theme.fonts.body};
`

const Layout = ({ children }) => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }
  useEffect(() => window.addEventListener('keydown', handleFirstTab), [])

  return (
    <Root className="siteRoot">
      <div className="siteContent">
        <Menu />
        <div id="main">
          {children}
          <Banner />
        </div>
      </div>
      <Footer />
      <Global styles={globalStyles} />
    </Root>
  )
}

export default Layout
