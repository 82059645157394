export const format = (date) => {
  return date.substring(0, 10).replaceAll('-', '/')
}

export const shuffle = (array) => {
  var m = array.length, t, i;
  while (m) {
    i = Math.floor(Math.random() * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }
  return array;
}

export const random = () => {
  var list = [];
  for (let i = 0; i < 30; i++) {
    list[i] = Math.random() * 20
  }
  return list;
}

export const ImageRandom = () => {
  var list = [];
  for (let i = 0; i < 5; i++) {
    list[i] = Math.random() * 5
  }
  return list;
}

export const replacePunctuation = (content) => {
  return content.substring(0, content.length - 5) + "..."
}
