export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("toFromKubaRole")
    ? JSON.parse(window.localStorage.getItem("toFromKubaRole"))
    : {}

const setUser = user =>
  window.localStorage.setItem("toFromKubaRole", JSON.stringify(user))

export const handleLogin = role => {
  if (role) {
    return setUser({
      role: role,
      expiredAt: Date.now() + 600000 //10分後
    })
  }

  return false
}

export const isLoggedIn = () => {
  const user = getUser()

  return !!(user.role && (user.expiredAt > Date.now()))
}

export const logout = callback => {
  setUser({})
  callback()
}
