import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby';

const FooterWrapper = styled(BackgroundImage)`
  width: 100vw;
  min-height: 4em;
  position: relative;
`

const Nav = styled.nav`
  width: 100vw;
  height: 5em;
  position: absolute;
  display: block;
  bottom: 0;
  backdrop-filter: blur(6px);
`

// const Wrapper = styled.footer`
//   display: inline-block;
//   padding: 2em 1em;
//   max-width: ${props => props.theme.sizes.maxWidth};
//   border-top: 1px solid #71767b;
//   margin: 0 auto;
// `

const FooterText = styled.p`
  line-height: 1.8em;
  display: inline-block;
  position: absolute;
  bottom: 0.5em;
  left: 3em;
  transform: scale(0.6, 0.9);
  font-size: 0.9em;
  @media screen and (max-width: 460px) {
    left: -3.5em;
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allContentfulAsset(filter: {title: {glob: "livistona_*"}}) {
        edges {
          node {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  `)
  const randomNumber = Math.floor(Math.random() * 5)
  const image = data.allContentfulAsset.edges[randomNumber]
  return (
    <>
      <FooterWrapper
        Tag="footer"
        fluid={image.node.fluid}
        backgroundColor={'#6d846f'}
      >
        <Nav>
          <FooterText>
            このサイトはGoogle Analyticsを使用しています。<br />
            © 2020 Arata Mino, Inunosenakaza, Kazuki Sugiura
          </FooterText>
        </Nav>
      </FooterWrapper>
    </>
  )
}

export default Footer
